import React, { useState, useEffect, useCallback } from 'react';
import ProductList from "../ProductList/ProductList";
import CategoriesList from "../CategoriesList/CategoriesList";

const MainPage = () => {

    const [categories, setCategories] = useState([]);
    const [token, setToken] = useState([]);
    const [products, setProducts] = useState([]);

    const creds = {
        "username": "timbot",
        "password": "jlsdASD_3msd"
    };

    const fetchData = async () => {
        const token = await fetch('https://itorba.by/rest/V1/integration/admin/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(creds)
        })
        .then(response => response.json())
        .catch(error => console.error(error));

        setToken(token);

        const cats = await fetch('https://itorba.by/rest/V1/categories/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .catch(error => console.error(error));

        const categoriesFinded = cats.children_data.filter(function(item){
            return item.product_count !== 0;
        });

        setCategories(categoriesFinded);

        const products = await fetch(`https://itorba.by/rest/default/V1/products-render-info?searchCriteria[pageSize]=10&currencyCode=BYN&storeId=1&fields=items[url,id,name,images,price_info[regular_price]]`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .catch(error => console.error(error));;

        setProducts(products.items);

    };

    useEffect(() => {
        fetchData()
    }, []);



    return (

        <section className='main-section'>
            <CategoriesList
                cats = {categories}
            />
            <ProductList
                prods = {products}
            />
        </section>
    );
};

export default MainPage;