import './App.css';
import { useEffect } from 'react';
import { useTelegram } from "./hooks/useTelegram";
import Header from "./components/Header/Header";
import {Route, Routes} from 'react-router-dom';
import FormPage from "./components/FormPage/FormPage";
import MainPage from './components/MainPage/MainPage';

const App = () => {

  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
  }, [tg])

  return (
    <div className="App">

      <Header />
      <Routes>
          <Route index element={<MainPage />}/>
          <Route path={'form'} element={<FormPage />}/>
      </Routes>

    </div>
  );
}

export default App;
