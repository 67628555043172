import React, { useState, useEffect } from 'react';
import './CategoriesList.css';

const CategoriesList = ({cats}) => {

    const catItems = cats.map(cat => 
        <li
            key={cat.id}
            className='item-link'
        >
            <a href={'rest/V1/categories/' + cat.id}>{cat.name}</a>
            
        </li>
    );
  
    // console.log(cats);

    return (
        <ul className='categories-list'> 
            {catItems}
        </ul>
    );

};

export default CategoriesList;