import React from 'react';
import Button from "../Button/Button";
import './ProductItem.css';

const ProductItem = ({product, className, onAdd}) => {

    const onAddHandler = () => {
        onAdd(product);
    }

    return (
        <div className={'product ' + className}>
            <div className={'img'}>
                <img src={product.images[0].url} alt={product.images[0].label} />
            </div>
            <div className={'title'}>
                <a href={product.url}>{product.name}</a>
            </div>
            <div className={'price'}>
                <span>Стоимость: <b>{product.price_info.regular_price}</b></span>
            </div>
            <Button className={'add-btn'} onClick={onAddHandler}>
                Добавить в корзину
            </Button>
        </div>
    );
};

export default ProductItem;
