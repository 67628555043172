import React, { useState, useCallback, useEffect } from 'react';
import './ProductList.css';
import ProductItem from "../ProductItem/ProductItem";
import { useTelegram } from "../../hooks/useTelegram";

const productso = [
    {id: '1', name: 'Адаптер Baseus Super Si Quick Charger 1C 20W EU Sets Black', price_info: 55    , url: '#'},
    {id: '2', name: 'Зарядное Baseus GaN3 Pro Desktop Fast Charger 2U+2C', price_info: 195, url: ''},
    {id: '3', name: 'Беспроводная зарядка Baseus Digital LED Display 2in1', price_info: 110, url: ''},
    {id: '4', name: 'Наушники Baseus Encok Type-C lateral in-ear Wired Earphone C17 White', price_info: 40, url: ''},
    {id: '5', name: 'Наушники проводные Hoco M1 Pro Type-C черные', price_info: 30, url: ''},
    {id: '6', name: 'чехол iphone 13 Pro Max прозрачный с визитницей', price_info: 20, url: ''},
    {id: '7', name: 'Защитное стекло Baseus All-glass Tempered Glass Film 0.3mm for iP Pro 6.1-inch 2022', price_info: 45, url: ''},
]

const getTotalPrice = (items = []) => {
    return items.reduce((acc, item) => {
        return acc += item.price_info.regular_price
    }, 0)
}

const ProductList = ({prods}) => {

    const [addedItems, setAddedItems] = useState([]);
    const {tg, queryId} = useTelegram();

    const onSendData = useCallback(() => {
        const data = {
            products: addedItems,
            totalPrice: getTotalPrice(addedItems),
            queryId,
        }

        fetch('https://x7.magenmagic.com:3001/web-data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    }, [addedItems, queryId])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [tg, onSendData])

    const onAdd = (prods) => {
        const alreadyAdded = addedItems.find(item => item.id === prods.id);
        let newItems = [];

        if(alreadyAdded) {
            newItems = addedItems.filter(item => item.id !== prods.id);
        } else {
            newItems = [...addedItems, prods];
        }

        setAddedItems(newItems)

        if(newItems.length === 0) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
            tg.MainButton.setParams({
                text: `Купить ${getTotalPrice(newItems)}`
            })
        }
    }

    return (

        <div className={'list'}>
            {prods.map(item => (
                <ProductItem
                    key={item.id}
                    product={item}
                    onAdd={onAdd}
                    className={'item'}
                />
            ))}
        </div>
    );
};

export default ProductList;
